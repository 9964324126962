import { defineStore } from 'pinia';

export const useSettlementStore = defineStore('settlement', () => {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const acceptanceId = ref<number>(0); // идентификатор заявки
    const currentStep = ref<number>(0); // текущий шаг приемки
    // массив шагов
    const steps = ref<string[] >([
        t('settlement.client.appointment.title'),
        t('settlement.client.personal_data.title'),
        t('settlement.client.date.title'),
        t('settlement.client.scheduled.title'),
        t('settlement.client.appointment.title'),
    ]);

    const goToNextStep = () => {
        if (currentStep.value < steps.value.length - 1) {
            currentStep.value++;
            updateRoute(currentStep.value);
        }
    };

    const updateRoute = (step: number) => {
        router.push({ 
            name: route.name,
            params: { id: acceptanceId.value },
            query: { step: step + 1 }
        });
    };

    return {
        acceptanceId,
        currentStep,
        goToNextStep,
        steps,
        updateRoute,
    };
});